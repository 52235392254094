import NavBar from "../components/nav_bar";
import AboutMe from "../components/about_me";
import Experience from "../components/experience";
import Education from "../components/education";
import Footer from "../components/footer";

const Main = () => {
    return(<>
      <NavBar />
      <AboutMe />
      <Experience />
      <Education />
      <Footer />
    </>
  );
}

export default Main;